import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

const inNumbersElements = (inNumbersQueryData: KenticoInNumbersSection): InNumbersElement[] => {
  const COLUMNS_NUMBER = 4
  const elements = []
  for (let i = 1; i <= COLUMNS_NUMBER; i++) {
    const element = {
      number: _get(inNumbersQueryData, `column_${i}_number.value`, ''),
      suffix: _get(inNumbersQueryData, `column_${i}_suffix.value`, ''),
      description: _get(inNumbersQueryData, `column_${i}_description.value`, ''),
    }
    elements.push(element)
  }
  return elements
}

/* Kentico model: InNumbers Section */
export interface KenticoInNumbersSection extends KontentItem {
  title: KontentTextElement
  background_color: KontentTextElement
  column_1_number: KontentNumberElement
  column_1_suffix: KontentTextElement
  column_1_description: KontentTextElement
  column_2_number: KontentNumberElement
  column_2_suffix: KontentTextElement
  column_2_description: KontentTextElement
  column_3_number: KontentNumberElement
  column_3_suffix: KontentTextElement
  column_3_description: KontentTextElement
  column_4_number: KontentNumberElement
  column_4_suffix: KontentTextElement
  column_4_description: KontentTextElement
}
export interface InNumbersElement {
  number: number,
  suffix: string,
  description: string
}
export class InNumbersSection {
  header: string
  backgroundColor: string
  elements: InNumbersElement[]

  constructor(data: KenticoInNumbersSection) {
    this.header = _get(data, 'title.value', '')
    this.backgroundColor = _get(data, 'background_color.value', '')
    this.elements = inNumbersElements(data)
  }
}

const inNumbersSectionFragment = graphql`
query InNumbersQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_in_numbers_section {
              elements {
                ...kontent_item_in_numbers_section_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const inNumbersSectionElementsFragment = graphql`
  fragment kontent_item_in_numbers_section_elementsFragment on kontent_item_in_numbers_section_elements {
    column_1_description {
      value
    }
    column_1_number {
      value
    }
    column_1_suffix {
      value
    }
    column_2_description {
      value
    }
    column_2_number {
      value
    }
    column_2_suffix {
      value
    }
    column_3_description {
      value
    }
    column_3_number {
      value
    }
    column_3_suffix {
      value
    }
    column_4_description {
      value
    }
    column_4_number {
      value
    }
    column_4_suffix {
      value
    }
    title {
      value
    }
    background_color {
      value
    }
  }
`

export const inNumbersFragment = graphql`
  fragment kontent_item_in_numbers_fragment on kontent_item_modular_content_element_value {
    value {
      ... on kontent_item_in_numbers_section {
        elements {
          ...kontent_item_in_numbers_section_elementsFragment
        }
        preferred_language
        system {
          codename
        }
      }
    }
  }
`

export const useInNumbersSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(inNumbersSectionFragment)
  const inNumbersSectionData: KenticoInNumbersSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new InNumbersSection(inNumbersSectionData)
}

export const useInNumbersDynamicData = (props: PageProps, path: string) => {
  return _get(props, `${path}.in_numbers_section.value`).length
    ? new InNumbersSection(_get(props, `${path}.in_numbers_section.value[0].elements`))
    : null
}
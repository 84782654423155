import * as React from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { decimalCount } from '../../common/serviceHelpers'
import { InNumbersSection } from './model'
import { AnimationService } from '../../common/AnimationService'
import { IsMobileContext } from '../../common/Context'
import './style.scss'

const InNumbers: React.FC<InNumbersSection> = ({ header, backgroundColor, elements }) => {

  const { isMobile } = React.useContext(IsMobileContext)
  const [viewPortEntered, setViewPortEntered] = React.useState(new Array(elements.length).fill(false))

  const headingRef = React.useRef<HTMLDivElement>(null)
  const columnsRefs = React.useRef<Array<HTMLDivElement>>([])
  React.useEffect(() => {
    if (isMobile !== undefined) {
      AnimationService.slideUp(headingRef.current)
      if (isMobile) {
        for (const el of columnsRefs.current) {
          AnimationService.slideUp(el)
        }
      } else {
        AnimationService.slideSequenceUp(columnsRefs.current)
      }
    }
  }, [isMobile])

  return (
    <section className="InNumbersSection GlobSectPadSpacing px-m" style={{ backgroundColor: backgroundColor }}>
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <div className={`grid grid-cols-1 gap-m ${elements[3].number ? 'lg:grid-cols-4' : 'lg:grid-cols-3'}`}>
        <h3 ref={headingRef} className={`FontL col-start-1 col-span-1 ${elements[3].number ? 'lg:col-span-4' : 'lg:col-span-3'}`}>
          {header}
        </h3>
        {elements && elements.map((element, i) => {
          if (element.number) {
            return (
              <div
                className='col-span-1 mb-0'
                key={i}
                ref={(el: HTMLDivElement) => columnsRefs.current[i] = el}
              >
                <CountUp 
                  start={Math.max(element.number-20, 0)}
                  end={viewPortEntered[i] ? element.number : 0}
                  decimals={Math.min(decimalCount(element.number), 2)}
                  suffix={element.suffix}
                  duration={1}
                  delay={0}
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      active={!viewPortEntered[i]}
                      onChange={(isVisible: boolean): void => {
                        if (isVisible) {
                          setViewPortEntered(prev => {
                            const newViewPortEntered = [...prev]
                            newViewPortEntered[i] = true
                            return newViewPortEntered
                          })
                        }
                      }}
                      delayedCall
                    >
                      <span ref={countUpRef} className='FontXXXL block'></span>
                    </VisibilitySensor>
                  )}
                </CountUp>
                <span className='FontM block mt-xs lg:mt-xs mb-0'>{element.description}</span>
              </div>
            )
          } else if (element.suffix) {
            return (
              <div 
                className='col-span-1 mb-0'
                key={i}
                ref={(el: HTMLDivElement) => columnsRefs.current[i] = el}
              >
                <span className='FontXXXL block'> {element.suffix}</span>
                <span className='FontM block mt-xs lg:mt-xs mb-0'>{element.description}</span>
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
      {backgroundColor && <div className="pt-mobile-m lg:pt-m" style={{ backgroundColor: backgroundColor }}></div>}
    </section>
  )
}

export default InNumbers
